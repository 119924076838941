import React from 'react';
import {
    Image,
    HStack,
    Box,
    Text,
    Heading,
    Button,
    Flex,
    Icon,
    Tag,
    TagLabel,
    Divider,
    Progress,
    Badge
} from '@chakra-ui/react';

interface OpportunityCardProps {
    title: string
    content: string
    hasRegistered: boolean
    loading: boolean
    tags: string[]
    onClick: () => void
}

function OpportunityCard({ onClick, title, content, hasRegistered, loading, tags }: OpportunityCardProps) {
    return (
        <Box h="100%" role={"group"} background={"cardBG"} padding={{ base: '24px', lg: '42px' }} borderRadius={"10px"} w="100%" maxWidth={"610px"}>
            <Flex direction={"column"} h="100%">
                <HStack w="100%" justifyContent="space-between">
                    <Heading textAlign={"left"} variant={"cardHeading"}>{title}</Heading>
                </HStack>

                <Divider my="1rem" />

                <Text textAlign={"left"} fontSize={{ base: '14px', lg: '16px' }} flex={2} alignItems="center" py="2rem" display={"flex"} fontWeight={300}>
                    {content}
                </Text>

                <HStack mb="2rem" w="100%" display="initial">
                    {tags.map(tag => <Badge>{tag}</Badge>)}
                </HStack>

                {hasRegistered &&
                    <Tag w="100%" size='lg' colorScheme='blue' borderRadius='full' textAlign="center">
                        <TagLabel w="100%">Thankyou for your interest</TagLabel>
                    </Tag>
                }

                {!hasRegistered &&
                    <>
                        {!loading ?
                            <Button onClick={onClick} w="100%">Register Interest</Button>
                            :
                            <Progress my="1rem" size='xs' isIndeterminate />
                        }
                    </>
                }
            </Flex>
        </Box>
    );
}

export default OpportunityCard;
