interface FAQ {
    title: string
    content: (string | string[])[]
}

const faq: FAQ[] = [
    {
        title: 'How does LinkerFi work?',
        content: [
            'LinkerFi Private Network is a collection of people with a shared understanding of cooperatively connecting and collaborating, sharing off-market deal flow, projects and career opportunities in a safe controlled environment.',
            "To get started with your account, follow the steps below:",
            [
                '**Set up your account** - Register and apply to the network - [**Click here**](https://www.linkerfi.com/contact.html)',
                '**Confirm  your credentials** - Click one-time email verification link.',
                '**Account Dashboard**  - Login in & stay up to date with news and opportunities.',
                "**Provide wallet address** -  Get assigned a User ID that links to your registration/s. If you don't have a digital wallet one will be provided to you.",
                "**Verification success** - Receive a non-transferable LinkerFi NFT that acts as your ID & Identifier for remuneration.",
                "**Leverage your social capital** - Think about the value you have, the value you could contribute and also the value you are looking for. Once you are clear on those you will be able to extract the most out of LinkerFi or [**build your own spreadsheet**](https://docs.google.com/spreadsheets/u/0/d/1230wBW1NTWa7OegAT6_j5AymB0b-dbSA-WSutjkS0uA/edit) to work out what to do with who you know."
            ]
        ]
    },
    {
        title: "How do I contact you?",
        content: [
            [
                "Register your interest to the network and we will contact you as soon as reasonably practicable.",
            ]
        ]
    },
    {
        title: "How can I earn money with linkerFi?",
        content: [
            'LinkerFi believes those who bring additional value to the network via referral deserve to be rewarded. Your User ID doubles as your identifier so we know who to remunerate for any input your contacts have within the network.'
        ]
    },
    {
        title: "How much does it cost to join?",
        content: [
            [
                "Nothing, you can only bring value to the network by connections and only monetary opportunities are available to sophisticated and accredited investors only.",
            ]
        ]
    },
    {
        title: "How do I find out what's going on?",
        content: [
            [
                "Verified users can access their account dashboard to review available opportunities relevant to the field and news updates of potential deal flow.",
            ]
        ]
    },
    {
        title: "I don't have a digital wallet?",
        content: [
            [
                "Don't worry click on - I don't own a digital wallet link on the connect with us  page and the team will arrange after verification.",
            ]
        ]
    },
    {
        title: "How do I set up a digital wallet?",
        content: [
            [
                "Watch the video setup link or go to [**MetaMask.io**](https://metamask.io) and click the “Download” button, which will take you to the relevant store to download the extension or app based on the device and browser you’re using.",
            ]
        ]
    },
    {
        title: "When was the network created?",
        content: [
            [
                "LinkerFi Private Network was established in 2022. But members of the network have been active in off market deal flow since 2018. The network has grown substantially since inception with members located throughout the globe. That’s when it was decided to take the ethos shared by the members, a collective effort of blockchain experts, tech entrepreneurs, professional services and key opinion leaders. The network is private and information on the team can be requested.",
            ]
        ]
    },
    {
        title: "Who owns LinkerFi?",
        content: [
            [
                "LinkerFi is owned by its members",
            ]
        ]
    },
    {
        title: "Venture Studio",
        content: [
            [
                "LinkerFi Venture Studio is an active connection of investors to projects & deals.",
            ]
        ]
    },
    {
        title: "Funds",
        content: [
            [
                "LinkerFi Funds is currently in the process of being established and is not yet fully operational. It is intended to be a passive fund that is only available to approved, eligible members from certain jurisdictions. LinkerFi is seeking experienced partners who are knowledgeable about managing and running these types of funds to join the team. It should be noted that LinkerFi Funds will be separate from the LinkerFi network and every effort will be made to ensure compliance and maximize the chances of success before any information about opportunities related to the fund is shared. At this time, LinkerFi Funds should not be considered as an offering.",
            ]
        ]
    },
    {
        title: "What kind of background do network members have?",
        content: [
                "LinkerFi is a private network with a diverse range of talent and expertise, including expertise in renewable energy solutions, cyber protection for multinationals, AI technologies, and film projects. Upon being verified as a member of the network, you will have access to a private Telegram group and user portal that provides constant updates and news about opportunities within the network. Verified members also receive a public passport that displays their verification and member status. Some members have been doing business with the network since 2018.",
                "**Industries include:**",
                [
                    "Finance",
                    "Real Estate",
                    "Information Technology",
                    "Sustainable Living",
                    "Cloud Computing",
                    "AI technology",
                    "Property Development",
                    "Logistics",
                    "Renewable energy",
                    "Blockchain",
                    "Commodities",
                    "Aviation",
                    "Tourism",
                    "Aerospace",
                    "AR/VR & Metaverse",
                    "Medical",
                    "And many more...",
                ]
            ]
    },
    {
        title: 'What is LinkerFi going to do in the future?',
        content: [
            [
                "LinkerFi's plan is to extend the network across the globe collecting the very best talent across all sectors. We don't know what the future holds but we will be prepared to deploy the network and flow.",
            ]
        ]
    },
    {
        title: 'What is the network policy?',
        content: [
            [
                "LinkerFi is an inclusive verified network that is using technology to do good business with good people for a good purpose. LinkerFi is a private network that is committed to maintaining a safe and inclusive environment for its members. As such, LinkerFi will not tolerate any illegal behavior, business propositions, or hateful messaging within the network. LinkerFi reserves the right to exclude any member who engages in such activities from the network, and any such exclusion will be at the sole discretion of LinkerFi. By joining the network, you agree to adhere to this policy and understand that any violation of this policy may result in your removal from the network. LinkerFi is not responsible for any actions or behavior of its members using the platform. You can view the [**terms & conditions here**](https://www.linkerfi.com/terms-conditions.html)",
            ]
        ]
    },
    {
        title: 'How do I delete my account?',
        content: [
            [
                "Please email a request to info@Linkerfi.com for account deletion using the same email that was registered. If you don't have access to this email anymore all unused accounts for 12 months will automatically be deleted.",
            ]
        ]
    },

    // {
    //     title: "What can I expect for myself and my network?",
    //     content: [
    //         '**Member Benefits** -  Verified members will have access to off market deal flow and opportunities that generally exclude non qualified or public interaction. Members can potentially use LinkerFi as a career launch pad with 1 degree of separation from some of the smartest organizations and minds on the planet.',
    //         '**You can expect:**',
    //         [
    //             "Your network to improve significantly",
    //             "Lots of collaboration",
    //             "A clear actionable route to leveraging your contact list and life changing events",
    //             "Access to leading experts in their fields",
    //             "Investment insights reserved for High Net Worth individuals",
    //             "Expanded knowledge of the trends affecting the future of your company and industry",
    //             "Time freedom - reduce work hours and creating more time to do  things you love and spend with the people you love (without any traditional courses or expenditure)",
    //             "A healthier body and mind",
    //             "Improved relationships at home",
    //             "An ethos to change the status quo of the matrix",
    //             "A level of personal development you may not actually be able to imagine right now",
    //         ],
    //         "Importantly you’ll experience more enjoyment of your already successful life, doing more of the things you really love, individually, and with the people that are important to you.",
    //         "We will introduce you to an understanding that you have everyone in your life to achieve what you desire. By leveraging those connections members can access potential remuneration and exposure to world leading projects and opportunities  just for the people they already know.",
    //         "We will assist you in showing you what to do with who you know which will cumulatively result in you gaining real joy and fulfillment."
    //     ]
    // },
]

export default faq