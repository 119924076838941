import React from 'react';
import {
    Image,
    HStack,
    Box,
    Text,
    Heading,
    Button,
    Flex,
    Icon,
    Tag,
    TagLabel,
    Divider
} from '@chakra-ui/react';
import { NewsArticle } from '../types';
import { FiCalendar, FiEye } from 'react-icons/fi';
import { GiHighFive } from 'react-icons/gi';

interface NewsCardProps {
    article: NewsArticle
    onClick: () => void
}

function NewsCard({ onClick, article: { title, intro, claps, readTime, createdAt } }: NewsCardProps) {
    return (
        <Box height="100%" role={"group"} background={"cardBG"} padding={{ base: '24px', lg: '42px' }} borderRadius={"10px"} w="100%" maxWidth={"610px"}>
            <Flex direction={"column"} h="100%">
                <HStack w="100%" justifyContent="space-between">
                    <Heading textAlign={"left"} variant={"cardHeading"}>{title}</Heading>
                </HStack>

                <Divider my="1rem" />
                <HStack>
                    <Tag size='lg' colorScheme='green' borderRadius='full'>
                        <Icon as={GiHighFive} mr="12px" />
                        <TagLabel>{claps}</TagLabel>
                    </Tag>
                </HStack>

                <HStack mt="1rem">
                    <Tag size='lg' borderRadius='full'>
                        <Icon as={FiCalendar} mr="12px" />
                        <TagLabel>{createdAt.toLocaleDateString()}</TagLabel>
                    </Tag>

                    <Tag size='lg' borderRadius='full'>
                        <Icon as={FiEye} mr="12px" />
                        <TagLabel>{`${readTime} min`}</TagLabel>
                    </Tag>
                </HStack>

                <Text textAlign={"left"} fontSize={{ base: '14px', lg: '16px' }} flex={2} alignItems="center" py="2rem" display={"flex"} fontWeight={300}>
                    {intro}
                </Text>

                <Button onClick={onClick} w="100%">Read more</Button>
            </Flex>
        </Box>
    );
}

export default NewsCard;
