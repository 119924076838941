import {
    extendTheme
} from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: `Syne, sans-serif`,
        body: `'Inter', sans-serif`,
    },
    colors: {
        cardBG: '#202020'
    },
    components: {
        Tabs: {
            variants: {
                'soft-rounded': {
                    tab: {
                        color: 'rgb(241, 241, 241)',
                        border: '1px solid rgb(241, 241, 241)',
                        marginLeft: '4px',
                        marginRight: '4px'
                    }
                }
            },
        },
        FormLabel: {
            baseStyle: {
                color: 'rgb(241, 241, 241)',
                marginTop: '1rem'
            }
        },
        Modal: {
            baseStyle: {
                dialog: {
                    bg: "#202020",
                    border: '1px solid rgb(241, 241, 241)'
                },
                header: {
                    color: 'rgb(241, 241, 241)',
                }
            },
        },
        Input: {
            baseStyle: {
                field: {
                    color: 'rgb(241, 241, 241)',
                    background: 'red'
                },
                text: {
                    background: 'rgb(241, 241, 241)'
                },
            }
        },
        FormControl: {
            baseStyle: {
                marginTop: '1rem'
            }
        },
        Text: {
            baseStyle: {
                color: '#aaaaaa',
                fontSize: '16px',
                lineHeight: '1.6em',
                // fontWeight: '700',
                cursor: 'default'
            },
            variants: {
                bold: {
                    fontWeight: '700'
                }
            }
        },
        Button: {
            cancel: {

            },
            baseStyle: {
                color: '#111',
                borderRadius: '100px',
                backgroundColor: 'rgb(241, 241, 241)',
                fontWeight: '400',
                letterSpacing: '1px',
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px !important',
                transition: '1000ms ease',
                // transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);',
                // transformStyle: 'preserve-3d',
                _hover: { backgroundColor: 'black !important', background: 'black ', color: "#F1F1F1" },
                textTransform: 'uppercase',
                // position: 'inherit'
            },
            variants: {
                cancel: {
                    border: '1px solid rgb(241, 241, 241)',
                    backgroundColor: '#111',
                    color: 'rgb(241, 241, 241)'
                },
                inverted: {
                    color: 'rgb(241, 241, 241)',
                    backgroundColor: '#202020',
                    _hover: { backgroundColor: 'rgb(241, 241, 241) !important', background: 'rgb(241, 241, 241) ', color: "black" },
                }
            },
            // variants: {
            //   main: {
            //     fontSize: '12px'
            //   }
            // },
            defaultProps: {
                fontSize: '12px'
            }
        },
        Box: {
            baseStyle: {
                transition: '1400ms ease',
            },
        },
        Tag: {
            variants: {
                huge: {
                    fontSize: '42px',
                }
            }
        },
        Heading: {
            baseStyle: {
                color: '#f1f1f1'
            },
            variants: {
                cardHeading: {
                    fontSize: '25px',
                    fontWeight: '700'
                }
            }
        }
    }
})

export default theme