export interface SignupForm {
    username: string
    firstName: string
    lastName: string
    password: string
    confirmPassword: string
    email: string
    referrer: string | undefined
}

export interface ResetPasswordForm {
    password: string
    confirmPassword: string
}

export interface SigninForm {
    password: string
    email: string
}

export interface ForgotPasswordForm {
    email: string
}

export enum AuthType {
    Login,
    Signup,
    ForgotPassword
}