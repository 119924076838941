import React from 'react';
import {
    Image,
    HStack,
    Box,
    Text,
    Heading,
    Button,
    Flex
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { PopupButton } from '@typeform/embed-react'
import { Application, TypeFormResponse } from '../types';
import Parse from 'parse'
import ApplicationStatusChip from './ApplicationStatusChip';
interface ApplicationCardProps {
    application: Application
}

function ApplicationStatusCard({application}: ApplicationCardProps) {
    return (
        <Box transition='1000ms ease' role={"group"} background={"cardBG"} padding="1rem" borderRadius={"10px"} w="100%">
            <HStack w="100%" justifyContent="space-between">
                <Heading size="md" textAlign={"left"}>{application.formName}</Heading>
                <ApplicationStatusChip status={application.status} />
            </HStack>
        </Box>
    );
}

export default ApplicationStatusCard;
