import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Image,
    HStack,
    IconButton,
    Box,
    Text,
    Heading,
    VStack,
    Button,
    Flex,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { PopupButton } from '@typeform/embed-react'
import { Application, TypeFormResponse } from '../types';
import ApplicationStatusChip from './ApplicationStatusChip';

interface SubCardProps {
    title: string
    description: string
    id: string
    application?: Application
    onApplyClick: (formId: string) => void
}

function SubCard({ title, description, id, onApplyClick, application }: SubCardProps) {
    return (
        <Box role={"group"} key={id} background={"cardBG"} padding="20px" borderRadius={"10px"} w="100%" maxWidth={"300px"}>
            <Flex direction={"column"} h="100%">
                <HStack w="100%">
                    <Heading fontWeight={700} textAlign={"left"} fontSize={"20px"} variant={"cardHeading"}>{title}</Heading>
                </HStack>


                <Text textAlign={"left"} fontSize="14px" flex={2} alignItems="center" py="20px" display={"flex"} fontWeight={300}>
                    {description}
                </Text>

                <Box w="100%" display={"flex"} justifyContent="start">
                    {application ?
                        <ApplicationStatusChip status={application.status} />
                        :
                        <Button onClick={() => onApplyClick(id)} w="110px" h="50px" py="14px" px="32px" fontSize={'12px'}>APPLY</Button>
                    }
                </Box>
            </Flex>
        </Box>
    );
}

export default SubCard;
