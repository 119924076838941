import { VStack, Heading, Text, FormLabel, Input, SimpleGrid, Button, useClipboard, HStack, Box, Container, Tag, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, ListItem, UnorderedList } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useAuth } from "src/modules/auth/AuthContext"
import Parse from 'parse'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { faq } from "src/types"
import { Questions } from "./components"
import { isArray } from "lodash"

const Opportunities = () => {
    // const { currentUser, referralLink } = useAuth()
    // const { hasCopied, onCopy } = useClipboard(referralLink || '')
    // const [role, setRole] = useState('')

    // useEffect(() => {
    //     getRole()
    // }, [])

    // const getRole = async () => {
    //     const query = new Parse.Query('Opportunity')
    //     const opportunity = await query.first()
    //     console.log("OPPO: ", opportunity?.attributes.info)
    //     setRole(opportunity?.attributes.info)
    // }

    return (
        <Container width={{ base: 'sm', lg: 'xl' }}>
            <VStack w="100%" textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem">
                <Heading mb="2rem" letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">FAQ</Heading>
                <Accordion w="100%" mt="2rem">
                    {faq.map((question) => (
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Heading my="1rem" size="sm" textAlign="left" w="100%">{question.title}</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box style={{ textAlign: 'left', color: '#AAAAAA' }}>
                                    {question.content.map(content => (
                                        <>
                                            {isArray(content) ?
                                                <>
                                                    <UnorderedList>
                                                        {content.map(innerContent =>
                                                            <ListItem><ReactMarkdown components={ChakraUIRenderer()}>{innerContent}</ReactMarkdown></ListItem>
                                                        )}
                                                    </UnorderedList>
                                                </>
                                                :
                                                <ReactMarkdown components={ChakraUIRenderer()}>{content}</ReactMarkdown>
                                            }
                                        </>
                                    ))}
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </VStack>
        </Container>
    )
}
export default Opportunities