import React, { useEffect, useState } from 'react';
import { Card, Text, CardBody, Image, Box, SkeletonCircle, SkeletonText, HStack, VStack, Heading, Icon, Button } from '@chakra-ui/react';
import { useAuth } from 'src/modules/auth/AuthContext';
import Parse from 'parse'
import Logo from '../images/Linkerfi-labs-logo.svg';
import { useAccount, useConnect, useSignMessage } from 'wagmi'
import { SiweMessage } from 'siwe'
import { GoVerified } from 'react-icons/go';
import Passport from 'src/types/Passport';

function LinkerFiPassport() {
    const { currentUser } = useAuth()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [passport, setPassport] = useState<Passport>()
    const { address } = useAccount()
    const { signMessageAsync } = useSignMessage()
    const { connect, connectors, isLoading, pendingConnector } = useConnect()

    useEffect(() => {
        console.log("USE EFF")
        getPassport()
    }, [])

    const getPassport = async () => {
        try {
            const passport = await Parse.Cloud.run('getPassport')
            setPassport(passport)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    const signIn = async () => {
        try {
            if (!address) return

            const message = new SiweMessage({
                domain: window.location.host,
                address,
                statement: 'LinkerFi Wallet Verification',
                uri: window.location.origin,
                version: '1',
                chainId: 1
            })

            const signature = await signMessageAsync({
                message: message.prepareMessage(),
            })

            const response = await Parse.Cloud.run('verifyWallet', {message, signature})            
            setPassport(response)
        } catch (_error: any) {
            setError(_error.message)
        }
    }

    return (
        <>
            {loading &&
                <Card variant="outline">
                    <Box padding='6'>
                        <SkeletonCircle size='10' />
                        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                    </Box>
                </Card>
            }

            {!loading && !passport &&
                <Card variant="outline">
                    <CardBody>
                        {!address &&
                            <VStack mt="1rem">
                                <Text>Looks like you don't have a LinkerFi Passport yet<br />Connect and verify your Ethereum wallet to start the process</Text>
                                
                                {connectors.filter((connector) => connector.ready).map((connector) => (
                                    <Button
                                        disabled={!connector.ready}
                                        key={connector.id}
                                        onClick={() => connect({ connector })}
                                        w="100%"
                                    >
                                        {connector.name}
                                        {!connector.ready && ' (unsupported)'}
                                        {isLoading &&
                                            connector.id === pendingConnector?.id &&
                                            ' (connecting)'}
                                    </Button>
                                ))}
                            </VStack>
                        }
                        {address &&
                            <VStack spacing="1rem">
                                <Text>Click "Verify" to sign a message with your wallet and start the verification process</Text>
                                <Button w="100%" onClick={signIn}>Verify</Button>
                            </VStack>
                        }
                    </CardBody>
                </Card>
            }

            {!loading && passport &&
                <Card variant="outline" borderRadius="1rem" p={4}>
                    <CardBody>
                        <VStack spacing={6}>
                            <Image
                                maxW="150px"
                                src={Logo}
                            />
                            <HStack><Heading size="lg">{`${currentUser?.get('firstName')} ${currentUser?.get('lastName')}`}</Heading>{passport.approvedApplications.length > 0 && <Icon boxSize={8} mt="4px" color="#98e1ff" as={GoVerified} />}</HStack>
                            <Text>{passport.approvedApplications.length > 0 ? 'Verified Linker' : 'Verification Pending'}</Text>
                        </VStack>
                    </CardBody>
                </Card>
            }

        </>
    );
}

export default LinkerFiPassport;
