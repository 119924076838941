import { VStack, Heading, Text, FormLabel, Input, SimpleGrid, Button, useClipboard, HStack, Box, Container, Tag } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useAuth } from "src/modules/auth/AuthContext"
import Parse from 'parse'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { FeedbackModal, LoadingCard } from "src/components"
import Opportunity from "src/types/Opportunity"
import OpportunityCard from "src/components/OpportunityCard"

const Opportunities = () => {
    const [opportunities, setOpportunities] = useState<Opportunity[]>([])
    const [loading, setLoading] = useState(true)
    const [registerLoading, setRegisterLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)


    useEffect(() => {
        getOpportunities()
    }, [])

    const getOpportunities = async () => {
        setLoading(true)
        const _opportunities = await Parse.Cloud.run('myOpportunities')
        setOpportunities(_opportunities)
        setLoading(false)
    }

    const onRegister = async (opportunityId: string) => {
        setRegisterLoading(true)
        try {
            await Parse.Cloud.run('registerInterest', { opportunityId })
            setOpportunities(opportunities.map(o => o.id === opportunityId ? {...o, hasRegistered: true} : {...o}))
            setRegisterLoading(false)
            setModalOpen(true)

        } catch (err) {
            setRegisterLoading(false)
        }
    }

    return (
        <VStack textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem">
            <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Opportunities</Heading>
            {loading &&
                <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px" padding={"20px"} w="100%">
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                </SimpleGrid>
            }
            {!loading && opportunities.length === 0 &&
                <Text>There are no current opportunities availiable linked linkerFi account at this time, this could be because you are still being verified or there is currently nothing available that matches your application submission. Please check the General News in the dashboard or contact our team directly if you have any questions.</Text>
            }
            {!loading && opportunities.length > 0 &&
                <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px" padding={"20px"} w="100%">
                    {opportunities.map(opportunity => (
                        <OpportunityCard key={opportunity.id} tags={opportunity.tags} loading={registerLoading} onClick={() => onRegister(opportunity.id)} title={opportunity.title} content={opportunity.content} hasRegistered={opportunity.hasRegistered} />
                    ))}
                </SimpleGrid>
            }
            <FeedbackModal open={modalOpen} onClose={() => setModalOpen(false)} title="Interest Registered" message="Thankyou for registering your interest, this will be reviewed by the LinkerFi team and we will respond to you via email."></FeedbackModal>
        </VStack>
    )
}
export default Opportunities