import { Modal, useDisclosure, Button, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Tabs, Tab, TabList, TabPanel, TabPanels, Progress, Heading, Text } from "@chakra-ui/react"
import { useRef, useState } from 'react'

export interface FeedbackModalProps {
    open: boolean
    title: string
    message: string
    buttonTitle?: string
    onClose: () => void
}

const FeedbackModal = ({ open, title, message, buttonTitle = 'OK', onClose }: FeedbackModalProps) => {

    return (
        <>
            <Modal
                isOpen={open}
                onClose={onClose}
                size="sm"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text>
                            {message}
                        </Text>

                        <Button w="100%" mt="2rem" onClick={onClose}>{buttonTitle}</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FeedbackModal