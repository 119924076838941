import { VStack, Heading, Text, FormLabel, Input, SimpleGrid, Button, useClipboard, HStack, Box, Container, Tag } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useAuth } from "src/modules/auth/AuthContext"
import Parse from 'parse'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import NewsCard from "src/components/NewsCard"
import { NewsArticle } from "src/types"
import { useNavigate } from "react-router-dom"
import { LoadingCard } from "src/components"

const NewsFeed = () => {
    const [newsFeed, setNewsFeed] = useState<NewsArticle[]>([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getRole()
    }, [])

    const getRole = async () => {
        const newsItems = await Parse.Cloud.run('getNewsItems')
        setNewsFeed(newsItems as NewsArticle[])
    }

    const onItemClick = (id: string) => {
        navigate(`${id}`)
    }

    return (
        <VStack textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem">
            <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">News Feed</Heading>
            <Box py="2rem" w="100%">
                {newsFeed.length ?
                    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px" padding={"20px"}>
                        {newsFeed.map(article => (
                            <NewsCard onClick={() => onItemClick(article.id)} article={article} />
                        ))}
                    </SimpleGrid>
                    :
                    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px" padding={"20px"} w="100%">
                        <LoadingCard />
                        <LoadingCard />
                        <LoadingCard />
                    </SimpleGrid>
                }
            </Box>
        </VStack>
    )
}
export default NewsFeed