import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './modules/auth/AuthContext';

interface ProtectedRouteProps {
    children: React.ReactNode
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { currentUser, loading } = useAuth()

    if (!currentUser && !loading) {
        return <Navigate to="/applications" replace />;
    }

    return <React.Fragment>{children}</React.Fragment>
};

export default ProtectedRoute
