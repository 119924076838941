import React, { ReactNode, useEffect, useState } from 'react';
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Image,
    useDisclosure,
    BoxProps,
    FlexProps,
    VStack,
    Button,
    Link,
    HStack
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiUser,
    FiUserMinus,
    FiAlertCircle,
    FiEdit3,
} from 'react-icons/fi';
import {
    HiOutlineNewspaper
} from 'react-icons/hi';

import {
    AiOutlineNodeIndex
} from 'react-icons/ai';

import { IconType } from 'react-icons';
import { ReactText } from 'react';
import LogoDark from '../../images/Linkerfi-labs-logo-lightm.svg';
import Logo from '../../images/Linkerfi-labs-logo.svg';
import { useAuth } from 'src/modules/auth/AuthContext';
import { AuthType } from 'src/modules/auth/types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import Parse from 'parse'
import { GoQuestion } from 'react-icons/go';

interface LinkItemProps {
    route: string
    name: string
    icon: IconType
}

const LinkItems: Array<LinkItemProps> = [
    { route: 'dashboard', name: 'Home', icon: FiHome },
    { route: 'applications', name: 'Applications', icon: FiEdit3 },
    { route: 'profile', name: 'Profile', icon: FiUser },
    { route: 'news', name: 'News', icon: HiOutlineNewspaper },
    { route: 'opportunities', name: 'Opportunities', icon: AiOutlineNodeIndex },
    { route: 'faq', name: 'FAQ', icon: GoQuestion },
];

const signoutLinkItem: LinkItemProps = { route: '/', name: 'SignOut', icon: FiUserMinus }

export default function SimpleSidebar({ children }: { children: ReactNode }) {
    const [marketStats, setMarketStats] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { currentUser, signOut, toggleOpen } = useAuth()

    useEffect(() => {
        getMarketStats()
    }, [])

    const getMarketStats = async () => {
        const marketStats = await Parse.Cloud.run('ticker')
        setMarketStats(marketStats)
    }

    return (
        <Box>
            <Box minH="100vh">
                <Box zIndex={1} pos="fixed" w="100vw" position={{ base: 'inherit', md: 'absolute' }}>
                    <Marquee gradient={false} style={{ position: 'fixed', background: '#111', zIndex: 1 }}>
                        <div dangerouslySetInnerHTML={{ __html: marketStats }}></div>
                    </Marquee>
                </Box>

                {currentUser ?
                    <Box>
                        <SidebarContent
                            onClose={() => onClose()}
                            display={{ base: 'none', md: 'block' }}
                        />
                        <Drawer
                            autoFocus={false}
                            isOpen={isOpen}
                            placement="left"
                            onClose={onClose}
                            returnFocusOnClose={false}
                            onOverlayClick={onClose}
                            size="full">
                            <DrawerContent>
                                <SidebarContent onClose={onClose} />
                            </DrawerContent>
                        </Drawer>
                        <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />

                    </Box>
                    :
                    <NavBar toggleOpen={toggleOpen} />
                }

                <Box ml={{ base: 0, md: currentUser ? 60 : 0 }} p="4" pt={!currentUser ? '4rem' : 4}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

interface NavBarProps {
    toggleOpen: (authType: AuthType) => void
}

const NavBar = ({ toggleOpen }: NavBarProps) => {
    return (
        <HStack pos="fixed" justify={"space-between"} width="100%" paddingX={'2rem'} pt={'3rem'}>
            <a href='https://linkerfi.com' target="_blank" rel="noopener noreferrer">
                <Box paddingX={{ base: '12px', lg: '96px' }}>
                    <Image src={Logo} width="130px" />
                </Box>
            </a>
            <Button onClick={() => toggleOpen(AuthType.Login)}>LogIn</Button>
        </HStack>
    )
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const { currentUser, signOut, toggleOpen } = useAuth()
    const location = useLocation()

    useEffect(() => {
        console.log("LOC: ", location.pathname.split('/'))
    }, [location])

    const onClick = (navItem: LinkItemProps) => {
        if (navItem.name === 'SignOut') {
            signOut()
        }
        onClose()
    }

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            zIndex={2}
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <a href='https://linkerfi.com' target="_blank" rel="noopener noreferrer">
                    <Image src={LogoDark} width="120px" />
                </a>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>

            {currentUser ?
                <Flex justify="space-between" h="100%" direction="column">
                    <Box>
                        {LinkItems.map((link, index) => (
                            <NavItem mt={index === LinkItems.length - 1 ? 'auto' : 'inherit'} route={link.route} onClick={() => onClick(link)} active={location.pathname.split('/')[1] === link.route} key={link.name} icon={link.icon}>
                                {link.name}
                            </NavItem>
                        ))}
                    </Box>
                    <NavItem mt="auto" mb="100px" route={signoutLinkItem.route} onClick={() => onClick(signoutLinkItem)} active={false} icon={signoutLinkItem.icon}>
                        {signoutLinkItem.name}
                    </NavItem>
                </Flex>
                :
                <VStack p={4}>
                    <Button w="100%" onClick={() => toggleOpen(AuthType.Login)}>LogIn</Button>
                    <Button w="100%" onClick={() => toggleOpen(AuthType.Signup)}>SignUp</Button>
                </VStack>
            }
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    active: boolean
    icon: IconType;
    children: ReactText;
    route: string
}
const NavItem = ({ icon, route, active, children, ...rest }: NavItemProps) => {
    return (
        <Link as={RouterLink} to={route} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="3"
                mx="4"
                my={1}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#202020',
                    color: 'white',
                }}
                bg={active ? '#202020' : 'inherit'}
                color={active ? 'white' : 'inherit'}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const { currentUser } = useAuth()

    return (
        <Flex
            ml={{ base: 0, md: currentUser ? 60 : 0 }}
            pt={6}
            px={{ base: 4, md: 24 }}
            height="24"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Image ml={8} src={LogoDark} width="120px" />
        </Flex>
    );
};