import { Modal, useDisclosure, Alert, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Tabs, Tab, TabList, TabPanel, TabPanels, Progress, Heading, Text, AlertIcon } from "@chakra-ui/react"
import { useRef, useState } from 'react'
import { ForgotPassword, SignIn, SignUp } from "./components"
import { AuthType, ForgotPasswordForm, SigninForm, SignupForm } from "./types"
import Parse from 'parse'
import { useAuth } from "./AuthContext"

interface AuthModalProps {
    open: boolean
    referrer?: string
    onClose: () => void
    authType: AuthType
    onAuthTypeChange: (authType: AuthType) => void
}

const AuthModal = ({ open, onClose, authType, onAuthTypeChange, referrer }: AuthModalProps) => {
    const { refreshUser, currentUser, toggleOpen } = useAuth()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const initialRef = useRef(null)
    const finalRef = useRef(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [hasPasswordResetSuccess, setHasPasswordResetSuccess] = useState(false)

    const handleSignin = async (values: SigninForm) => {
        setLoading(true)
        try {
            setErrorMessage('')
            await Parse.User.logIn(values.email, values.password)
            refreshUser()
            setLoading(false)
            toggleOpen()
        } catch (error: any) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    const handleSignup = async (values: SignupForm) => {
        setLoading(true)
        const user = new Parse.User();
        user.set("password", values.password);
        user.set("email", values.email);
        user.setUsername(values.username)
        user.set('firstName', values.firstName)
        user.set('lastName', values.lastName)
        user.set('referrer', values.referrer)

        try {
            setErrorMessage('')
            await user.signUp();
            refreshUser()
            setLoading(false)
            toggleOpen()
            // setSuccess(true)
        } catch (error: any) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    const handleResetPassword = async (values: ForgotPasswordForm) => {
        setLoading(true)
        try {
            await Parse.User.requestPasswordReset(values.email)
            setHasPasswordResetSuccess(true)
            setLoading(false)    
        } catch (error: any) {
            setLoading(false)
            setErrorMessage(error.message)
        }
    }

    const handlePasswordResetSuccessConfirm = () => {
        setHasPasswordResetSuccess(false)
        onAuthTypeChange(AuthType.Login)
    }

    const handleClose = () => {
        setErrorMessage('')
        setSuccess(false)
        onClose()
    }

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={open}
                onClose={handleClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{authType === AuthType.ForgotPassword ? 'Forgot Password' : 'Connect With LinkerFi'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {errorMessage &&
                            <Alert borderRadius="full" mb="1rem" status='error'>
                                <AlertIcon />
                                {errorMessage}
                            </Alert>
                        }

                        {authType === AuthType.ForgotPassword &&
                            <>
                            <ForgotPassword onCancel={handlePasswordResetSuccessConfirm} hasSuccess={hasPasswordResetSuccess} onForgotPasswordSubmit={handleResetPassword} onSuccessConfirm={handlePasswordResetSuccessConfirm} loading={loading} />
                            </>
                        }

                        {authType !== AuthType.ForgotPassword &&
                            <>
                                {!success &&
                                    <Tabs index={authType} onChange={(value) => onAuthTypeChange(value)} variant='soft-rounded' colorScheme='white' isFitted>
                                        <TabList>
                                            <Tab _selected={{ bg: 'rgb(241, 241, 241)', color: '#202020' }}>Sign In</Tab>
                                            <Tab _selected={{ bg: 'rgb(241, 241, 241)', color: '#202020' }}>Sign Up</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <SignIn onForgotPasswordClick={() => onAuthTypeChange(AuthType.ForgotPassword)} loading={loading} onSignin={handleSignin} />
                                            </TabPanel>
                                            <TabPanel>
                                                <SignUp loading={loading} onSignup={handleSignup} />
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                }

                                {success &&
                                    <>
                                        <Heading size="md" color="black">Success!</Heading>
                                        <Text mt="2rem">
                                            A welcome text has been sent to {currentUser?.getEmail()}<br></br><br></br>
                                            Please verify your email to continue
                                        </Text>
                                    </>
                                }

                                {loading &&
                                    <Progress size="lg" isIndeterminate />
                                }
                            </>
                        }

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AuthModal