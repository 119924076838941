import { FormControl, FormLabel, Input, Button, FormErrorMessage, Box, VStack, Link, ButtonGroup, Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react"
import { Formik, Field } from "formik";
import { ForgotPasswordForm } from "../types";

const validate = (values: ForgotPasswordForm) => {
    const errors: any = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors
};

interface ForgotPasswordProps {
    onForgotPasswordSubmit: (value: ForgotPasswordForm) => void
    onSuccessConfirm: () => void
    onCancel: () => void
    loading: boolean
    hasSuccess: boolean
}

const Signin = ({ onForgotPasswordSubmit, onSuccessConfirm, onCancel, hasSuccess, loading }: ForgotPasswordProps) => {
    return (
        <VStack w="100%">
            <Box w="100%">
                <Formik initialValues={{
                    email: '',
                    password: ''
                }}
                    validate={validate}
                    isInitialValid={false}
                    onSubmit={(values) => onForgotPasswordSubmit(values)}>
                    {({ handleSubmit, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={!!errors.email} mt="1rem">
                                <FormLabel>Username / Email</FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>

                            {!loading && !hasSuccess &&
                                <VStack>

                                    <Button type="submit" mt="2rem" mr={3} w="100%">
                                        Reset Password
                                    </Button>

                                    <Button onClick={onCancel} variant="cancel" mt="2rem" mr={3} w="100%">
                                        Cancel
                                    </Button>

                                </VStack>
                            }

                            {hasSuccess &&
                                <VStack w="100%">
                                    <Alert
                                        status='info'
                                        variant='subtle'
                                        flexDirection='column'
                                        alignItems='center'
                                        justifyContent='center'
                                        textAlign='center'
                                        my="2rem"
                                    >
                                        <AlertIcon boxSize='40px' mr={0} />
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                                            Password reset submitted
                                        </AlertTitle>
                                        <AlertDescription maxWidth='sm'>
                                            If your email exists on our system you will receive a link to reset your password
                                        </AlertDescription>
                                    </Alert>
                                    <Button onClick={onCancel} mt="2rem" mr={3} w="100%">
                                        OK
                                    </Button>
                                </VStack>
                            }
                        </form>
                    )}
                </Formik>
            </Box>
        </VStack>
    )
}

export default Signin
