import React, { useEffect, useState } from 'react';
import {
    Text,
    Heading,
    VStack,
    SimpleGrid,
    Container,
} from '@chakra-ui/react';
import { FeedbackModal } from 'src/components';
import { useAuth } from '../../modules/auth/AuthContext';
import { createPopup } from '@typeform/embed'
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordForm } from 'src/modules/auth/components';

function ResetPassword() {
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
    const [username, setUsername] = useState('')
    const [token, setToken] = useState('')
    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(search)
        setUsername(params.get('username')!)
        setToken(params.get('token')!)
    }, []);

    const onSuccess = () => {
        setFeedbackModalOpen(true)
    }

    return (
        <>
            <Container maxW="md">
                <VStack w="100%" textAlign="center" fontSize="xl" spacing={"3rem"} my="4rem">
                    <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Reset Password</Heading>
                    <Text fontWeight={300} maxWidth={"640px"} padding="24px" fontSize={{ base: '14px', lg: '16px' }}>
                        Use the form below to reset your password
                    </Text>
                    <ResetPasswordForm onSuccess={onSuccess} username={username} token={token} />
                    <FeedbackModal open={feedbackModalOpen} onClose={() => navigate('/')} title="Success" message="Your password has been reset, you can now login again from the home page"></FeedbackModal>
                </VStack>
            </Container>
        </>
    );
}

export default ResetPassword;
