import { FormControl, FormLabel, Input, Button, FormErrorMessage, HStack, Container } from "@chakra-ui/react"
import { Formik, Field } from "formik";
import { ResetPasswordForm, SignupForm } from "../types";
import Parse from 'parse'
import { debounce } from 'lodash';
import { useMemo, useState } from "react";
import axios from 'axios'

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: ResetPasswordForm) => {

    const errors: any = {};

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(values.password)) {
        errors.password = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    }

    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Password does not match'
    }

    return errors
};

interface ResetPasswordProps {
    username: string
    token: string
    onSuccess: () => void
}

const ResetPassword = ({ username, token, onSuccess }: ResetPasswordProps) => {
    const [loading, setLoading] = useState(false)

    const handleReset = async (values: ResetPasswordForm) => {
        const params = {
            username,
            token,
            new_password: values.password,
            // appId: 'linkerfi'
        }
        console.log("VALS: ", params)

        const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:1337/parse'

        try {
            setLoading(true)
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('token', token);
            params.append('new_password', values.password);
            await axios.post(`${serverUrl}/apps/linkerfi/request_password_reset`, params);
            onSuccess()
        } catch (err: any) {
            console.log("ERR")
        }
    }

    return (
        <Formik initialValues={{
            password: '',
            confirmPassword: '',
        }}
            validate={validate}
            onSubmit={(values) => handleReset(values)}>
            {({ handleSubmit, errors, touched }) => (
                <form style={{ width: '100%' }} onSubmit={handleSubmit}>

                    <FormControl isInvalid={!!errors.password && touched.password}>
                        <FormLabel>Password</FormLabel>
                        <Field
                            as={Input}
                            id="password"
                            name="password"
                            type="password"
                        />
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.confirmPassword && touched.confirmPassword}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Field
                            as={Input}
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                        />
                        <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                    </FormControl>

                    {!loading &&
                        <Button type="submit" mt="2rem" mr={3} w="100%">
                            Reset
                        </Button>
                    }
                </form>
            )}
        </Formik>
    )
}

export default ResetPassword