import { VStack, Heading, Text, FormLabel, Input, SimpleGrid, Button, useClipboard, HStack, Box, Container, Tag, SkeletonCircle, SkeletonText, Icon, TagLabel, Tooltip, Card, CardBody, Divider, Flex } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useAuth } from "src/modules/auth/AuthContext"
import Parse from 'parse'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import NewsCard from "src/components/NewsCard"
import { NewsArticle } from "src/types"
import { useParams, useSearchParams } from "react-router-dom"
import { debounce } from "lodash"
import { GiHighFive } from "react-icons/gi"
import { useDebounce } from 'use-debounce';

const NewsArticlePage = () => {
    const [article, setArticle] = useState<NewsArticle>()
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const { articleId } = params
    const [claps, setClaps] = useState(0)
    const [userClaps, setUserClaps] = useState(0)
    const [debouncedClaps] = useDebounce(claps, 1000);
    const [initialUserClaps, setInitialUserClaps] = useState(0)

    useEffect(() => {
        console.log("params: ", params)
        if (articleId) {
            getRole()
        }
    }, [params])

    useEffect(() => {
        const asyncUseEffect = async () => {
            const clapCount = claps
            setClaps(0)
            const response = await Parse.Cloud.run('addClaps', { clapCount, newsItemId: articleId })
        }
        if (claps !== initialUserClaps && claps > 0) {
            asyncUseEffect()
        }
    }, [debouncedClaps])

    const onClap = () => {
        if (userClaps < 10) {
            setArticle({
                ...article!,
                claps: article!.claps + 1
            })
            setClaps(claps + 1)
            setUserClaps(userClaps + 1)
        }
    }

    const getRole = async () => {
        setLoading(true)
        const response = await Parse.Cloud.run('getNewsItem', { newsItemId: articleId })
        setArticle(response.article)
        setUserClaps(response.userClaps)
        setLoading(false)
    }

    // const onClap = () => {
    //     setClaps(claps + 1)
    //     debouncedClap()
    // }

    // const handleDebouncedClap = async () => {
    //     const clapCount = claps
    //     await Parse.Cloud.run('addClaps', { clapCount, newsItemId: article!.id })
    // }

    // const debouncedClap = useCallback(debounce(handleDebouncedClap, 1000), [claps, article]);

    return (
        <Container width={{ base: 'sm', lg: 'xl' }}>
            <VStack textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem" w="100%">
                {/* <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Linker News</Heading> */}
                {article ?
                    <>
                        <VStack textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem">
                            <Heading mb="2rem" as="h2" size="2xl" textAlign="left" letterSpacing="-0.05em" fontWeight="700">{article.title}</Heading>
                            <Box style={{ textAlign: 'left', color: '#AAAAAA' }}>
                                <ReactMarkdown components={ChakraUIRenderer()}>{article.content}</ReactMarkdown>
                            </Box>

                            <Divider />
                            {/* <Card w="100%">
                                <CardBody> */}
                            <Text w="100%" textAlign="left">Show your appreciation by clapping for this post</Text>
                            <Flex w="100%" justifyContent="start">
                                <Box onClick={() => onClap()}>
                                    <Tooltip isDisabled={userClaps + claps < 10} label="Max claps exceeded (10)">
                                        <Tag p="0.5rem" paddingX="1rem" cursor="pointer" size='lg' colorScheme='green' borderRadius='full'>
                                            <Icon ml="1rem" fontSize="1.5rem" as={GiHighFive} mr="12px" />
                                            <TagLabel fontSize="1.5rem" mr="1rem">{article.claps}</TagLabel>
                                        </Tag>
                                    </Tooltip>
                                </Box>
                            </Flex>
                            {/* </CardBody>
                            </Card> */}
                        </VStack>
                    </>
                    :
                    <>
                        <Box padding='6' w="100%" boxShadow='lg'>
                            <SkeletonCircle size='10' />
                            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                        </Box>
                    </>
                }
            </VStack>
        </Container>
    )
}
export default NewsArticlePage