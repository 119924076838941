import React, { useEffect, useState } from 'react';
import {
  Text,
  Heading,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { Referral, MainCard, SubCard, FeedbackModal } from 'src/components';
import { useAuth } from '../../modules/auth/AuthContext';
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import { mainOptions, subOptions } from 'src/types/options';

function Home() {
  const [selectedFormId, setSelectedFormId] = useState<string>()
  const { currentUser, toggleOpen, applications, submitFormResponse } = useAuth()
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

  useEffect(() => {
    if (selectedFormId && currentUser) {
      onApplyClick(selectedFormId)
      setSelectedFormId(undefined)
    }
  }, [currentUser])

  const onApplyClick = (formId: string): void => {
    const hasCompleted = applications.find(a => a.formId === formId)

    if (hasCompleted) {
      setFeedbackModalOpen(true)
      return
    }

    if (!currentUser) {
      setSelectedFormId(formId)
      toggleOpen()
    } else {
      setSelectedFormId(undefined)
      createPopup(formId, {
        open: 'time',
        openValue: 1,
        onSubmit: response => onFormComplete(response.responseId, formId),
        hidden: {
          user_id: currentUser.id
        }
      })
    }
  }

  const onFormComplete = (responseId: string, formId: string) => {
    submitFormResponse(responseId, formId)
  }

  return (
    <>
      <VStack textAlign="center" fontSize="xl" spacing={"3rem"} my="4rem">
        <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Join the <span className="gradient-span">Lab.</span></Heading>
        <Text fontWeight={300} maxWidth={"640px"} padding="24px" fontSize={{ base: '14px', lg: '16px' }}>
          LinkerFi takes pride in the power of our secure network, we cultivate constructive connections whilst maintaining the privacy of our users. Apply to join the Lab via your relevant form below.        </Text>
        {/* <Referral /> */}
        <>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="40px" padding={"20px"}>
            {mainOptions.map(option => (
              <MainCard {...option} onApplyClick={onApplyClick} application={applications.find(a => a.formId === option.id)} />
            ))}
          </SimpleGrid>

          <Heading fontSize={"25px"}>Human Capital</Heading>

          <Text marginTop={"24px !important"}>Skills, Knowledge, Experience & Connections.</Text>

          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px">
            {subOptions.map(option => (
              <SubCard {...option} onApplyClick={onApplyClick} application={applications.find(a => a.formId === option.id)} />
            ))}
          </SimpleGrid>
        </>
        <FeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)} title="Slow Down..." message="Looks like you've already completed this form, you can check the state of your application in the profile section"></FeedbackModal>
      </VStack>
    </>
  );
}

export default Home;
