import React, { useMemo } from 'react';
import {
    Tag,
    TagLabel
} from '@chakra-ui/react';

interface ApplicaitonStatusChipProps {
    status: number
}

function ApplicationStatusChip({status}: ApplicaitonStatusChipProps) {
    const color = useMemo(() => {
        switch (status) {
            case 0:
                return 'blue'
            case 1:
                return 'green'

            default:
                return 'orange';
        }
    }, [status])

    const title = useMemo(() => {
        switch (status) {
            case 0:
                return 'PENDING'
            case 1:
                return 'APPROVED'
            default:
                return 'DENIED';
        }

    }, [status])

    return (
        <Tag size='lg' colorScheme={color} borderRadius='full'>
            <TagLabel>{title}</TagLabel>
        </Tag>
    )
}

export default ApplicationStatusChip;
