import React from 'react';
import {
    Image,
    HStack,
    Box,
    Text,
    Heading,
    Button,
    Flex
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { PopupButton } from '@typeform/embed-react'
import { Application, TypeFormResponse } from '../types';
import Parse from 'parse'
import ApplicationStatusChip from './ApplicationStatusChip';
interface MainCardProps {
    title: string
    description: string
    id: string
    imgName: string
    application?: Application
    onApplyClick: (formId: string) => void
}

function MainCard({ title, description, id, imgName, onApplyClick, application }: MainCardProps) {
    return (
        <Box role={"group"} key={id} background={"cardBG"} padding={{ base: '24px', lg: '42px' }} borderRadius={"10px"} w="100%" maxWidth={"610px"}>
            <Flex direction={"column"} h="100%">
                <HStack w="100%">
                    <Box>
                        {imgName &&
                            <Box backgroundColor={"#111"} borderRadius="100%" w="60px" h="60px" display={"flex"} justifyContent="center" alignItems={"center"}>
                                <Image src={require(`../images/${imgName}`)} />
                            </Box>
                        }
                    </Box>
                    <Heading textAlign={"left"} variant={"cardHeading"}>{title}</Heading>
                </HStack>


                <Text textAlign={"left"} fontSize={{ base: '14px', lg: '16px' }} flex={2} alignItems="center" py="4rem" display={"flex"}>
                    {description}
                </Text>

                <Box w="100%" display={"flex"} justifyContent="start">
                    {application ?
                        <ApplicationStatusChip status={application.status} />
                        :
                        <Button onClick={() => onApplyClick(id)} w="110px" h="50px" py="14px" px="32px" fontSize={'12px'}>APPLY</Button>
                    }
                </Box>
            </Flex>
        </Box>
    );
}

export default MainCard;
