import { VStack, Heading, Text, FormLabel, Input, SimpleGrid, Button, useClipboard, HStack, Box, Container, Tag, Alert, AlertDescription, AlertIcon, AlertTitle, Divider, Badge } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { LinkerFiPassport } from "src/components"
import ApplicationStatusCard from "src/components/ApplicationStatusCard"
import { useAuth } from "src/modules/auth/AuthContext"
import Parse from 'parse'

interface ReferralCount {
    pendingReferrals: number
    verifiedReferrals: number
}

const Profile = () => {
    const { currentUser, referralLink, emailVerified, resendVerification, applications, verifiedReferrer } = useAuth()
    const { hasCopied, onCopy } = useClipboard(referralLink || '')
    const [verificationSent, setVerificationSent] = useState(false)
    const [passport, setPassport] = useState()
    const [referrals, setReferrals] = useState<ReferralCount>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        initProfile()
    }, [])

    const onSendVerification = () => {
        setVerificationSent(true)
        resendVerification()
    }

    const copyReferral = () => {
        navigator.clipboard.writeText(referralLink);
        onCopy()
    }

    const initProfile = async () => {
        const data = await Parse.Cloud.run('initProfile')
        setReferrals(data.referrals)
    }

    return (
        <Container>
            <VStack textAlign="center" fontSize="xl" spacing={"1rem"} my="4rem">
                <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Profile</Heading>

                {/* <Heading w="100%" mt="2rem" textAlign="left" size="lg">{`${currentUser?.get('firstName')} ${currentUser?.get('lastName')}`}</Heading> */}
                <SimpleGrid columns={{ base: 1, lg: 2 }} w="100%" spacing="1rem">
                    <Box w="100%">
                        <FormLabel>First Name</FormLabel>
                        <Input w="100%" value={currentUser?.get('firstName')} disabled></Input>
                    </Box>

                    <Box w="100%">
                        <FormLabel>Last Name</FormLabel>
                        <Input w="100%" value={currentUser?.get('lastName')} disabled></Input>
                    </Box>

                    <Box w="100%">
                        <FormLabel>Username</FormLabel>
                        <Input w="100%" value={currentUser?.getUsername()} disabled></Input>
                    </Box>

                    <Box w="100%">
                        <FormLabel>Email</FormLabel>
                        <Input w="100%" value={currentUser?.getEmail()} disabled></Input>
                    </Box>
                </SimpleGrid>

                <Divider />
                {/* <Box w="100%">
                    <FormLabel>Username</FormLabel>
                    <HStack spacing={"1rem"} justify="space-between">
                        <Text textAlign="left">{currentUser?.getUsername()}</Text>
                    </HStack>
                </Box>

                <Box w="100%">
                    <FormLabel><Tag variant='solid' colorScheme='red' mr="1rem">!</Tag>Email</FormLabel>
                    <HStack spacing={"1rem"} justify="space-between">
                        <Text textAlign="left">{currentUser?.getEmail()}</Text>
                    </HStack>
                    {!emailVerified &&
                        <HStack justifyContent="space-between">
                            <Text cursor="pointer">re-send verification email</Text>
                        </HStack>
                    }
                </Box> */}

                {!emailVerified && !verificationSent &&
                    <>
                        <Alert
                            status='warning'
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                        >
                            <AlertIcon boxSize='40px' mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                Email Verification Required!
                            </AlertTitle>
                            <AlertDescription maxWidth='sm'>
                                Please verify your email to gain access to more services
                            </AlertDescription>
                        </Alert>

                        <Button onClick={() => onSendVerification()} w="100%">re-send email verification</Button>
                    </>
                }

                {!emailVerified && verificationSent &&
                    <>
                        <Alert status='info'>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>Verification Sent!</AlertTitle>
                                <AlertDescription>
                                    {`Email verification has been sent to ${currentUser?.getEmail()}`}
                                </AlertDescription>
                            </Box>
                        </Alert>
                    </>
                }


                {emailVerified &&
                    <>
                        {verifiedReferrer &&
                            <Box w="100%">
                                <FormLabel>Referral Link</FormLabel>
                                <HStack spacing={"1rem"} justify="space-between">
                                    <Text textAlign="left">{referralLink}</Text>
                                    <Button size="sm" onClick={copyReferral}>{hasCopied ? 'COPIED' : 'COPY'}</Button>
                                </HStack>
                            </Box>
                        }

                        {/* <Box w="100%">
                            <FormLabel>Telegram Invite</FormLabel>
                            <HStack spacing={"1rem"} justify="space-between">
                                <Text cursor="pointer"><a href="">https://telegram/invitelink</a></Text>
                                <Button size="sm" onClick={onCopy}>OPEN</Button>
                            </HStack>
                        </Box> */}

                        <Box mt="2rem" w="100%">
                            <Heading mt="2rem" mb="2rem" size="lg">LinkerFi Passport</Heading>

                            <LinkerFiPassport />
                        </Box>

                        <Box mt="2rem" w="100%">
                            <Heading mt="2rem" mb="2rem" size="lg">My Applications</Heading>

                            {!applications.length ?
                                <Alert status='info' borderRadius="full">
                                    <AlertIcon />
                                    <Box>
                                        <AlertTitle>No Applications</AlertTitle>
                                        <AlertDescription>
                                            You haven't created any applications, you can do this from the home page
                                        </AlertDescription>
                                    </Box>
                                </Alert>
                                :
                                applications.map(application => <Box mt="1rem"><ApplicationStatusCard application={application} /></Box>)
                            }
                        </Box>

                        {verifiedReferrer &&
                            <Box mt="2rem" w="100%">
                                <Heading mt="2rem" mb="2rem" size="lg">My Referrals</Heading>

                                <HStack spacing={"1rem"} justify="space-between">
                                    <Text textAlign="left">Pending:</Text>
                                    <Tag>{referrals?.pendingReferrals}</Tag>
                                </HStack>

                                <HStack mt="1rem" spacing={"1rem"} justify="space-between">
                                    <Text textAlign="left">Verified:</Text>
                                    <Tag>{referrals?.verifiedReferrals}</Tag>
                                </HStack>

                            </Box>
                        }
                    </>
                }
            </VStack>
        </Container>
    )
}
export default Profile