import React from 'react';
import {
    Box,
    SkeletonCircle,
    SkeletonText
} from '@chakra-ui/react';

function NewsCard() {
    return (
        <Box transition='1000ms ease' role={"group"} background={"cardBG"} padding={{ base: '24px', lg: '42px' }} borderRadius={"10px"} w="100%" maxWidth={"610px"} _hover={{
            background: 'white',
        }}>
            <Box padding='6' w="100%" boxShadow='lg'>
                <SkeletonCircle size='10' />
                <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
        </Box>
    );
}

export default NewsCard;
