import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import AuthProvider, { useAuth } from './modules/auth/AuthContext';
import Parse from 'parse'
import process from 'process';
import "./css/App.css"
import {
  Home, NewsArticle, NewsFeed, Profile, Opportunities, ResetPassword, FAQ, Dashboard
} from './pages'
import { Layout } from './modules';
import ProtectedRoute from './ProtectedRoute';
import LogRocket from 'logrocket';
import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'

import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import 'swiper/css';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [publicProvider()],
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
})

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);

Parse.initialize("linkerfi", "");
Parse.serverURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:1337/parse'

LogRocket.init('ggp4qr/linkerfi');

root.render(
  <StrictMode>
    <Router>
      <WagmiConfig client={client}>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <ColorModeScript />
            <Layout>
              <Routes>
                <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>
                <Route path="/applications" element={<Home />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/opportunities" element={<ProtectedRoute><Opportunities /></ProtectedRoute>} />
                <Route path="/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
                <Route path="/news" element={<ProtectedRoute><NewsFeed /></ProtectedRoute>} />
                <Route path="/news/:articleId" element={<ProtectedRoute><NewsArticle /></ProtectedRoute>} />
              </Routes>
            </Layout>
          </AuthProvider>
        </ChakraProvider>
      </WagmiConfig>
    </Router>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
