import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Parse from 'parse'
import AuthModal from './AuthModal';
import EmailVerifiedModal from './EmailVerifiedModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthType } from './types';
import useLocalStorage from "use-local-storage";
import { Application } from 'src/types';

type AuthContextState = {
    currentUser?: Parse.User
    modalOpen: boolean
    emailVerified: boolean
    referralLink: string
    applications: Application[]
    loading: boolean
    toggleOpen: (authType?: AuthType) => void
    refreshUser: () => void
    signOut: () => void
    submitFormResponse: (responseId: string, formId: string) => void
    resendVerification: () => void
    referrer: string | undefined
    verifiedReferrer: boolean
};

const initialValue: AuthContextState = {
    modalOpen: false,
    emailVerified: false,
    referralLink: '',
    applications: [],
    loading: true,
    toggleOpen: () => { },
    refreshUser: () => { },
    signOut: () => { },
    submitFormResponse: () => { },
    resendVerification: () => { },
    referrer: '',
    verifiedReferrer: false
};

export const AuthContext = React.createContext<AuthContextState>(initialValue);

interface AuthProviderProps {
    children: any;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
    const [referrer, setReferrer] = useLocalStorage<string | undefined>('referrer', '')
    const [loading, setLoading] = useState(initialValue.loading)
    const [currentUser, setCurrentUser] = useState(initialValue.currentUser)
    const [modalOpen, setModalOpen] = useState(initialValue.modalOpen)
    const [emailVerified, setEmailVerified] = useState(initialValue.emailVerified)
    const [emailVerifiedModalOpen, setEmailVerifiedModalOpen] = useState(initialValue.emailVerified)
    const [referralLink, setReferralLink] = useState(initialValue.referralLink)
    const [verifiedReferrer, setVerifiedReferrer] = useState(initialValue.verifiedReferrer)
    const [authType, setAuthType] = useState(AuthType.Login)
    const [applications, setApplications] = useState(initialValue.applications)
    const { search } = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        refreshUser()
        const params = new URLSearchParams(search)
        if (params.get('emailSuccess')) {
            setEmailVerifiedModalOpen(true)
            navigate('/', { replace: true })
        }
        if (params.get('ref')) {
            setReferrer(params.get('ref') || '')
            navigate({ pathname: '/' })
        }
    }, []);

    const refreshUser = async () => {
        setLoading(true)
        const user = Parse.User.current()
        if (user) {
            try {
                await user?.fetch()
                console.log("USER: ", user)        
            } catch(error: any) {
                if (error.code === 209) {
                    await signOut()
                }
                navigate('/applications')
            }
            const verified = await Parse.Cloud.run('emailVerified')
            setReferralLink(`https://www.app.linkerfi.com?ref=${user.getUsername()}`)
            setEmailVerified(verified)
            setVerifiedReferrer(user.attributes.verifiedReferrer)
            await fetchApplications()
        } else {
            setEmailVerified(false)
            setReferralLink('')
        }
        setCurrentUser(user)
        setLoading(false)
    }

    const toggleOpen = (_authType?: AuthType) => {
        setAuthType(_authType || AuthType.Login)
        setModalOpen(!modalOpen)
    }

    const signOut = async () => {
        await Parse.User.logOut()
        refreshUser()
    }

    const fetchApplications = async () => {
        const userApplications = await Parse.Cloud.run('myApplications')
        setApplications(userApplications)
    }

    const submitFormResponse = async (responseId: string, formId: string) => {
        try {
            const application = await Parse.Cloud.run('submitApplication', { responseId, formId })
            setApplications([...applications, application])
        } catch (error) {
            console.error(error)
        }
    }

    const resendVerification = async () => {
        Parse.User.requestEmailVerification(currentUser?.getEmail()!)
    }

    return (
        <AuthContext.Provider value={{ currentUser, loading, modalOpen, emailVerified, referralLink, applications, referrer, verifiedReferrer, refreshUser, toggleOpen, signOut, submitFormResponse, resendVerification }}>
            {children}
            <AuthModal referrer={referrer} onAuthTypeChange={(value) => setAuthType(value)} authType={authType} open={modalOpen} onClose={toggleOpen} />
            <EmailVerifiedModal open={emailVerifiedModalOpen} onClose={() => setEmailVerifiedModalOpen(false)} />
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
