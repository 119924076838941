import React, { useEffect, useState } from 'react';
import {
  Text,
  Heading,
  VStack,
  SimpleGrid,
  HStack,
  Button,
  Container,
  Divider,
  Box,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { Referral, MainCard, SubCard, FeedbackModal, LoadingCard } from 'src/components';
import { useAuth } from '../../modules/auth/AuthContext';
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import { mainOptions, subOptions } from 'src/types/options';
import Parse from 'parse'
import { NewsArticle } from 'src/types';
import Opportunity from 'src/types/Opportunity';
import { Swiper, SwiperSlide } from 'swiper/react';
import NewsCard from 'src/components/NewsCard';
import OpportunityCard from 'src/components/OpportunityCard';
import { Navigation } from "swiper";
import "swiper/css/navigation";
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [selectedFormId, setSelectedFormId] = useState<string>()
  const { currentUser, toggleOpen, applications, submitFormResponse } = useAuth()
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newsFeed, setNewsFeed] = useState<NewsArticle[]>([])
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const navigate = useNavigate()
  const [registerLoading, setRegisterLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    initDashboard()
  }, [])

  const initDashboard = async () => {
    setLoading(true)
    const data = await Parse.Cloud.run('initDashboard')
    setOpportunities(data.opportunities)
    setNewsFeed(data.newsFeed)
    setLoading(false)
  }

  const onRegister = async (opportunityId: string) => {
    setRegisterLoading(true)
    try {
      await Parse.Cloud.run('registerInterest', { opportunityId })
      setOpportunities(opportunities.map(o => o.id === opportunityId ? { ...o, hasRegistered: true } : { ...o }))
      setRegisterLoading(false)
      setModalOpen(true)

    } catch (err) {
      setRegisterLoading(false)
    }
  }

  return (
    <>
      <VStack px={{ base: 0, lg: 40 }} textAlign="center" fontSize="xl" spacing={"3rem"} my="4rem">
        <Heading letterSpacing="-0.05em" fontSize={{ base: '42px', lg: '54px' }} fontWeight="700">Join the <span className="gradient-span">Lab.</span></Heading>
        <Text fontWeight={300} maxWidth={"640px"} padding="24px" fontSize={{ base: '14px', lg: '16px' }}>
          LinkerFi takes pride in the power of our secure network, we cultivate constructive connections whilst maintaining the privacy of our users. Apply to join the Lab via your relevant form below.        </Text>

        {loading &&
          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="40px" padding={"20px"} w="100%">
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </SimpleGrid>
        }

        {!loading &&
          <VStack w="100%">
            <HStack w="100%" justify="start">
              <Heading mt="2rem" mb="2rem" size="xl" mr="2rem">Opportunities</Heading>
              <Button variant="inverted" onClick={() => navigate('/opportunities')} size="xs" ml="2rem">View All</Button>
            </HStack>

            <Flex w="100%" alignItems="stretch">
              <VStack justify="center" mr="1rem" display={{ base: 'none', md: 'inherit' }}>
                <IconButton variant="inverted" className='opportunities-prev' aria-label='' icon={<HiChevronLeft />} />
              </VStack>

              <Swiper
                spaceBetween={50}
                slidesPerView={2}
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  }
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                style={{ width: '100%' }}
                navigation={{
                  prevEl: '.opportunities-prev',
                  nextEl: '.opportunities-next',
                }}
                modules={[Navigation]}
              >
                {opportunities.map(o => (
                  <SwiperSlide>
                    <OpportunityCard title={o.title} content={o.content} hasRegistered={o.hasRegistered} loading={registerLoading} onClick={() => onRegister(o.id)} tags={o.tags} />
                  </SwiperSlide>
                ))}
              </Swiper>

              <VStack justify="center" ml="1rem" display={{ base: 'none', md: 'inherit' }}>
                <IconButton className='opportunities-next' aria-label='' icon={<HiChevronRight />} />
              </VStack>

            </Flex>

            <Box w="100%">
              <Divider my="4rem" />
            </Box>



            {/* NEWS FEED */}


            <HStack w="100%" justify="start">
              <Heading mt="2rem" mb="2rem" size="xl" mr="2rem">News Feed</Heading>
              <Button onClick={() => navigate('/news')} variant="inverted" size="sm" ml="2rem">View All</Button>
            </HStack>

            <Flex w="100%" alignItems="stretch">
              <VStack justify="center" mr="1rem" display={{ base: 'none', md: 'inherit' }}>
                <IconButton className='news-prev' aria-label='' icon={<HiChevronLeft />} />
              </VStack>

              <Swiper
                spaceBetween={50}
                slidesPerView={2}
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  }
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                style={{ width: '100%' }}
                navigation={{
                  prevEl: '.news-prev',
                  nextEl: '.news-next',
                }}
                modules={[Navigation]}
              >
                {newsFeed.map(n => (
                  <SwiperSlide>
                    <NewsCard article={n} onClick={() => navigate('/news/' + n.id)} />
                  </SwiperSlide>
                ))}
              </Swiper>

              <VStack justify="center" ml="1rem" display={{ base: 'none', md: 'inherit' }}>
                <IconButton className='news-next' aria-label='' icon={<HiChevronRight />} />
              </VStack>

            </Flex>

          </VStack>
        }

        <FeedbackModal open={modalOpen} onClose={() => setModalOpen(false)} title="Interest Registered" message="Thankyou for registering your interest, this will be reviewed by the LinkerFi team and we will respond to you via email."></FeedbackModal>
      </VStack>
    </>
  );
}

export default Home;
