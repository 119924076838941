import { FormControl, FormLabel, Input, Button, FormErrorMessage, HStack, Badge, VStack, Box } from "@chakra-ui/react"
import { Formik, Field } from "formik";
import { SignupForm } from "../types";
import Parse from 'parse'
import { debounce } from 'lodash';
import { useMemo } from "react";
import { useAuth } from "../AuthContext";

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: SignupForm) => {

    const errors: any = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!values.referrer) {
        errors.referrer = 'Required';
    }

    if (!values.username) {
        errors.username = 'Required';
    } else if (!/^\w+$/.test(values.username)) {
        errors.username = 'Letters, numbers and "_" only';
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(values.password)) {
        errors.password = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    }

    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Password does not match'
    }

    if (!values.firstName) {
        errors.firstName = 'First name required'
    }

    if (!values.lastName) {
        errors.lastName = 'Last name required'
    }

    return errors
};

interface SignupProps {
    onSignup: (value: SignupForm) => void
    loading: boolean
}

const SignUp = ({ onSignup, loading }: SignupProps) => {
    const { referrer } = useAuth()

    return (
        <Formik initialValues={{
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            firstName: '',
            lastName: '',
            referrer
        }}
            validate={validate}
            onSubmit={(values) => onSignup(values)}>
            {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                    <FormControl isInvalid={!!errors.referrer && touched.referrer}>
                        <FormLabel>Referrer</FormLabel>
                        <Field
                            as={Input}
                            id="referrer"
                            name="referrer"
                            type="text"
                        />
                        <Badge variant='outline' colorScheme="blue">*Approved referrers only</Badge>
                    </FormControl>

                    <FormControl isInvalid={!!errors.username && touched.username}>
                        <FormLabel>Username</FormLabel>
                        <Field
                            as={Input}
                            id="username"
                            name="username"
                            type="text"
                            loading={true}
                        // validate={debouncedUsernameValidation}
                        />
                        <FormErrorMessage>{errors.username}</FormErrorMessage>
                    </FormControl>

                    <HStack>
                        <FormControl isInvalid={!!errors.firstName && touched.firstName}>
                            <FormLabel>First Name</FormLabel>
                            <Field
                                as={Input}
                                id="firstName"
                                name="firstName"
                                type="text"
                            />
                            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.lastName && touched.lastName}>
                            <FormLabel>Last Name</FormLabel>
                            <Field
                                as={Input}
                                id="lastName"
                                name="lastName"
                                type="text"
                            />
                            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                        </FormControl>
                    </HStack>

                    <FormControl isInvalid={!!errors.email && touched.email} mt="1rem">
                        <FormLabel>Email</FormLabel>
                        <Field
                            as={Input}
                            id="email"
                            name="email"
                            type="email"
                        // validate={debouncedEmailValidation}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.password && touched.password}>
                        <FormLabel>Password</FormLabel>
                        <Field
                            as={Input}
                            id="password"
                            name="password"
                            type="password"
                        />
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.confirmPassword && touched.confirmPassword}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Field
                            as={Input}
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                        />
                        <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                    </FormControl>

                    {!loading &&
                        <Button type="submit" mt="2rem" mr={3} w="100%">
                            Signup
                        </Button>
                    }
                </form>
            )}
        </Formik>
    )
}

export default SignUp