import { FormControl, FormLabel, Input, Button, FormErrorMessage, Box, VStack, Link } from "@chakra-ui/react"
import { Formik, Field } from "formik";
import { SigninForm, SignupForm } from "../types";

const validate = (values: SigninForm) => {
    const errors: any = {};

    // if (!values.email) {
    //     errors.email = 'Required';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //     errors.email = 'Invalid email address';
    // }

    if (!values.password) {
        errors.password = 'Required';
    }
    return errors
};

interface SigninProps {
    onSignin: (value: SigninForm) => void
    loading: boolean
    onForgotPasswordClick: () => void
}

const Signin = ({ onSignin, loading, onForgotPasswordClick }: SigninProps) => {
    return (
        <VStack w="100%">
            <Box w="100%">
                <Formik initialValues={{
                    email: '',
                    password: ''
                }}
                    validate={validate}
                    onSubmit={(values) => onSignin(values)}>
                    {({ handleSubmit, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={!!errors.email} mt="1rem">
                                <FormLabel>Username / Email</FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!errors.password}>
                                <FormLabel>Password</FormLabel>
                                <Field
                                    as={Input}
                                    id="password"
                                    name="password"
                                    type="password"
                                />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>

                            {!loading &&
                                <Button type="submit" mt="2rem" mr={3} w="100%">
                                    Sign In
                                </Button>
                            }
                        </form>
                    )}
                </Formik>
            </Box>
            <Box pt="1rem">
                <Link color="white" onClick={onForgotPasswordClick}>Forgot Password</Link>
            </Box>
        </VStack>
    )
}

export default Signin

{/* <Link as={RouterLink} to={route} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}> */}
