export const mainOptions = [
    {
        title: 'Founders & Innovators',
        description: 'LinkerFi offers business founders, owners & innovative tech creators the opportunity to gain access to our network. If you seek investment or a professional team to help execute & launch your project or deal - you could be a fit for the LinkerFi network.',
        id: 'NKaIi22F',
        imgName: 'badge-icon-dark-light.svg'
    },
    {
        title: 'Investors',
        description: 'LinkerFi offer a healthy range of potential investment opportunities and invite arrangements involving non-monetary investment (contacts, resources etc.) If you have the time, business acumen, appropriate industry experience, or curiosity to try something new, you could be a fit for the LinkerFi Network.',
        id: 'V9oOfj9L',
        imgName: 'team-icon-dark-light.svg'
    }
]

export const subOptions = [
    {
        title: 'Developers',
        description: 'Front-end, Back-end, Web3 contract wizards.',
        id: 'WXWIeO3A',
    },
    {
        title: 'Designers',
        description: 'Graphic Designers, Illustrators, Animators, 3D Designers, Branding Specialists.',
        id: 'L2QK3UWX',
    },
    {
        title: 'Professional Services',
        description: 'Legal, Advisory, Strategists, Analysts & Accounting.',
        id: 'kJBVNpXD',
    },
    {
        title: 'Marketing',
        description: 'Key Opinion Leaders, Creative Directors, Ambassadors, Talent Managers & Community Leaders.',
        id: 'q5o0NHPa',
    },
    {
        title: 'Curators',
        description: 'Press and Media Publications, Journalists, Content Creators & more.',
        id: 'gYk7aeWO',
    },
    {
        title: 'Linkers',
        description: 'Networkers, Introducers, Entrepreneurs, Intermediaries, Brokers & more.',
        id: 'O3UoNZ0K',
    }
]
