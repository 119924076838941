import React from 'react';
import {
    HStack,
} from '@chakra-ui/react';
  
function Footer() {
    return (
        <HStack justify={"space-between"} width="100%" p="1rem" h="450px">
            {/* <Image src={Logo} width="130px"/> */}
        </HStack>
    );
}

export default Footer;
