import { Modal, useDisclosure, Button, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Tabs, Tab, TabList, TabPanel, TabPanels, Progress, Heading, Text } from "@chakra-ui/react"
import { useRef, useState } from 'react'
import { SignIn, SignUp } from "./components"
import { SigninForm, SignupForm } from "./types"
import Parse from 'parse'
import { useAuth } from "./AuthContext"

interface EmailVerifiedModalProps {
    onClose: () => void
    open: boolean
}

const EmailVerifiedModal = ({ onClose, open }: EmailVerifiedModalProps) => {
    const { currentUser } = useAuth()

    return (
        <>
            <Modal
                isOpen={open}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Email Verified</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text mt="2rem">
                            Your email has been succesfully verified. You can start completing applications
                        </Text>

                        <Button type="button" onClick={onClose} mt="2rem" mr={3} w="100%">
                            OK
                        </Button>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EmailVerifiedModal